import React from 'react'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage'


const PostCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  date,
  categories = [],
  className = '',
  ...props
}) => {

  return (
    <div
      class="rounded overflow-hidden shadow-lg m-4 row-span-2	hover:shadow-2xl"
    >
      <Link to={slug}>
        <div className="w-full rounded-full">
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredImage,
              alt: `featured image thumbnail for post ${title}`
            }}
          ></PreviewCompatibleImage>
        </div>
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">{title}</div>
          <p class="text-gray-700 text-base">{excerpt}</p>
        </div>
        <div class="px-6 py-4">
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
            {categories && categories.map((cat) => cat.category).join(', ')}
          </span>
        </div>
      </Link>
    </div>
  )
}

export default PostCard
