import React from 'react'

import ProjectCardSection from './ProjectCardSection'
class PostSection extends React.Component {
  render() {
    const { section } = this.props

    return (
      <div
        className="text-center section2"
        data-sal="slide-up"
        data-sal-duration="400"
        data-sal-easing="ease"
      >
        {section.title && <h2 className="pb-8 text-2xl">{section.title}</h2>}
        <p className="pb-12">{section.description}</p>

        <div
          className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-4"
          data-sal="slide-up"
          data-sal-duration="400"
          data-sal-easing="ease"
        >
          {section.card.map((post, index) => (
            <ProjectCardSection key={post.title + index} {...post} />
          ))}
        </div>
      </div>
    )
  }
}

export default PostSection
