import React from 'react'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage'

const PostCard = ({
  featuredImage,
  title,
  slug,
  content,
}) => {

  // make a new comma splited array from single array
  // const tag = tags[0].split(',')
  return (
    <div className="max-w-sm rounded-lg overflow-hidden shadow-lg bg-white m-4">
      <Link to={slug}>
        <div class="flex flex-col min-h-full">
          <div class="w-full">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage,
                alt: `featured image thumbnail for post ${title}`
              }}
            ></PreviewCompatibleImage>
          </div>
          <div class="text-center pt-3 ">
            <h3 class="text-gray-900 text-uppercase text-xl md:text-2xl">{title}</h3>
          </div>
          <div class="px-2 md:px-5 py-3 flex flex-wrap text-left flex-grow">
            <p>{content}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostCard
