import React from 'react'
import { Link } from 'gatsby'

import { Film, Instagram, Layers, Globe, Eye, ArrowRight } from 'react-feather'

class PostSection extends React.Component {
  renderIcon(selectedIcon) {
    switch (selectedIcon) {
      case 'film': {
        return <Film size="32px" color="#ff9000" className="md:-ml-8 mr-2" />
      }
      case 'bisnis': {
        return <Layers size="32px" color="#ff9000" className="md:-ml-8 mr-2" />
      }
      case 'aplikasi': {
        return <Globe size="32px" color="#ff9000" className="md:-ml-8 mr-2" />
      }
      case 'desain': {
        return <Eye size="32px" color="#ff9000" className="md:-ml-8 mr-2" />
      }
      case 'sosmed': {
        return (
          <Instagram size="32px" color="#ff9000" className="md:-ml-8 mr-2" />
        )
      }
      default: {
        //statements;
        break
      }
    }
  }

  render() {
    const { service } = this.props

    return (
      <>
        {service.serviceList.map(({ title, List, icon, cta, manualCTA }) => (
          <div
            className="max-w-sm w-full lg:max-w-full lg:flex px-4"
            data-sal="slide-up"
            data-sal-duration="400"
            data-sal-easing="ease"
            data-sal-delay="500"
          >
            <div className=" bg-white  p-4 flex flex-col justify-between leading-normal">
              <div className="mb-8">
                <div className="text-sm text-gray-900 flex items-center pb-4">
                  {this.renderIcon(icon)}
                  {/* {icons ? icons : ''}param */}
                  {/* <Icon.Camera className="md:-ml-8 mr-2" /> */}
                  <h3 className="pb-4 text-xl">{title}</h3>
                </div>

                <ul className="list-outside list-disc pb-4">
                  {List.map(({ title }) => (
                    <li>{title}</li>
                  ))}
                </ul>
                {cta ? (
                  <Link
                    className="font-bold text-gray-800 mt-8 pt-2 hover:text-orange-sherpa"
                    to={'service/' + cta }
                  >
                    Learn More <ArrowRight size="16" className="inline-block "/>
                  </Link>
                ) : (
                  ''
                )}
                {manualCTA ? (
                  <Link
                    className="font-bold text-gray-800 mt-8 pt-2 hover:text-orange-sherpa"
                    to={'service/' + manualCTA }
                  >
                    Learn More <ArrowRight size="16" className="inline-block "/>
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default PostSection
