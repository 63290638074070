import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PageHeader from '../components/PageHeader'
import SectionProject from '../components/HomePage/SectionProject'
import SectionService from '../components/HomePage/SectionService'
import SectionBlog from '../components/HomePage/SectionBlog'

import Layout from '../components/Layout'
import { Link } from '@reach/router'

import { ArrowRight } from 'react-feather'


// Export Template for use in CMS preview
export const HomePageTemplate = ({
  title,
  subtitle,
  featuredImage,
  client,
  section2 = [],
  service = [],
  clientSection,
  posts = []
}) => {
  return (
    <Fragment>
      <PageHeader
        large
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

      <div className="py-12 md:px-24 " style={{ backgroundColor: '#f7f7f7' }}>
        <SectionProject section={section2} />
      </div>

      <div className="py-12 md:px-24 ">
        <div className="text-center section2">
          {service.title && <h2 className="pb-8 text-2xl">{service.title}</h2>}
          <p className="pb-12">{service.subtitle}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 text-left">
            <SectionService service={service}></SectionService>
          </div>
        </div>
      </div>

      <div
        class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6"
        style={{ backgroundColor: '#f7f7f7' }}
      >
        <div class="flex justify-center">
          <ul class="flex flex-wrap justify-center">
            {client[0].currentclient.map(({ featuredImage, clientig }) => (
              <li className="mr-6">
                <a href={clientig} target="_blank" rel="noreferrer">
                  <Img
                    fluid={featuredImage.childImageSharp.fluid}
                    className="w-32 h-32"
                    alt={`featured image thumbnail for post ${title}`}
                  />
                </a>
              </li>
            ))}
            {/* {client[0].pastclient.map(({ featuredImage, pastclientig }) => (
                  <li className="mr-6">
                    <a href={pastclientig} target="_blank" rel="noreferrer">
                      <Img
                        fluid={featuredImage.childImageSharp.fluid}
                        className="w-32 h-32"
                        alt={`featured image thumbnail for post ${title}`}
                      />
                    </a>
                  </li>
                ))} */}
          </ul>
        </div>
        {/* fix see config */}
        <Link to="/project" className="text-center hover:text-orange-sherpa"> <h5>{clientSection.title}<ArrowRight size="16" className="inline-block "/></h5> </Link>
        
      </div>

      <div className="py-12 md:px-24">
        <div className="text-center section2">
          <h2 className="pb-8 text-2xl">BLOG</h2>
          <p className="pb-12">
            WHY CREATING AND SUPPORT YOUR BUSINESS EXPERIENCE FOR YOUR BRAND IS
            IMPORTANT
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 text-left">
            {posts.map((post, index) => (
              <SectionBlog {...post}></SectionBlog>
            ))}
          </div>
        </div>
      </div>

      {/* <div>
        <div className="markdown" dangerouslySetInnerHTML={{ __html: body }} />
      </div> */}
    </Fragment>
  )
}

// Export Default HomePage for front-end
const HomePage = ({ data: { page, client, posts } }) => {
  return (
    <Layout meta={page.frontmatter.meta || false}>
      <HomePageTemplate
        {...page}
        {...page.frontmatter}
        client={client.edges.map((client) => ({
          ...client.node.frontmatter.client
        }))}
        posts={posts.edges.map((post) => ({
          ...post.node,
          ...post.node.frontmatter,
          ...post.node.fields
        }))}
        body={page.html}
      ></HomePageTemplate>
    </Layout>
  )
}
export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        section2 {
          description
          title
          card {
            content
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 98) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        service {
          subtitle
          title
          serviceList {
            title
            icon
            cta
            manualCTA
            List {
              title
            }
          }
        }
        clientSection {
          title
          clientCTA
        }
      }
    }

    client: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "ProjectIndex" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            client {
              currentclient {
                clientig
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            client {
              pastclient {
                pastclientig
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 98) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories {
              category
            }
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 98) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
